import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Layout from '../components/layout'
import * as styles from '../components/hero.module.css'


class About extends React.Component {
  render() {
    const posts = get(this, 'props.data.allContentfulBlogPost.nodes')

    return (
      <Layout location={this.props.location}>
        <div className={styles.details}>
            <h1 className={styles.title}>About Me</h1>
            <p className={styles.aboutMeContent}>
                My name is Darren Ong. I am currently a Full Stack Software Engineer based in Singapore.
                I am passionate about building web and mobile applications that will benefit users.
                I also enjoy exploring new technologies such as Blockchain, AI and Cloud Computing.
                <br/>
                <br/>
                I am currently writing blog articles to share my knowledge with others and improve my writing skills.
                <br/>
                <br/>
                For more information, you can check out my <a href="https://www.linkedin.com/in/dtcarrot/" className={styles.link}>LinkedIn</a> or <a href="https://docs.google.com/document/d/1KmzItIK87J-cigRhBkykz4TB2OsNlIuVG-yoEBxD9fo/edit?usp=sharing" className={styles.link}>Resume</a>.
            </p>
            <br/>
        </div>
      </Layout>
    )
  }
}

export default About